import React, { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { VariantAltImage } from '@Types/product/Variant';
import { FiCheck } from 'react-icons/fi';
import Loader from 'components/commercetools-ui/loader/Loader';
import { TENDER_TYPE } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop, mediumDesktop } from 'helpers/utils/screensizes';
import { useAccount, useProduct } from 'frontastic';
import { getWishlist, addToWishlist, removeLineItem } from 'frontastic/actions/wishlist';
const LoginModal = dynamic(() => import('../../Modals/Login/login'));
import CustomMessageModal from '../../Modals/maxLimitModal';
import CompareProducts from '../compareSection';
import PriceDetails from './priceDetails';
import ProductCell from './productCell';

export default function ProductTile({
  products,
  totalCount,
  selectedPageView,
  handleCompareButtonClick,
  compareProducts,
  setCompareProducts,
}) {
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();
  const [wishListItems, setWishlistItems] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const { loggedIn, previewDateInSessionData, previewDateSessionDataLoading, account } = useAccount();
  const { getDroplets, getSkusExternalPrices, getAltImages } = useProduct();
  const { data: wishlist } = getWishlist(loggedIn);
  const [isdesktop] = useMediaQuery(desktop - 1);
  const [ismediumDesktop] = useMediaQuery(mediumDesktop);
  const screenSize = ismediumDesktop ? 4 : isdesktop ? 3 : 2;
  const [plpDroplets, setPlpDroplets] = useState([]);
  const [customMsgModal, setCustomMsgModal] = useState(null);
  const [futurePricing, setfuturePricing] = useState([]);
  const [altImages, setAltImages] = useState<VariantAltImage[]>([]);
  const [hoverImage, setHoverImage] = useState(null);
  const imagehoverStateRef = useRef({ isHovered: false, activeSku: null, abortController: null });
  let productCount = screenSize;

  const CompareSection = (variant) => {
    const extractedData = {
      skuId: variant?.skuId,
      mediumImage: variant?.mediumImage || '',
      displayName: variant?.displayName || '',
    };
    const handleButtonClick = () => {
      handleCompareButtonClick(extractedData);
    };
    return (
      <button
        onClick={() => handleButtonClick()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleButtonClick();
          }
        }}
        tabIndex={0}
        className="flex w-fit gap-x-2 md:mt-3"
      >
        <div className="flex h-[18px] w-[18px] items-center justify-center rounded border border-[#0000004D]">
          {compareProducts?.some((item) => item?.skuId === variant?.skuId) ? (
            <FiCheck className="h-full w-full text-blue-900" />
          ) : null}
        </div>
        <span className="text-sm leading-5 text-blue-900">
          {formatMessage({ id: 'compareProduct', defaultMessage: 'Compare' })}
        </span>
      </button>
    );
  };

  const LoginForPriceSection = () => {
    return (
      <span
        onClick={() => {
          setLoginModal(true);
        }}
        className=" w-fit cursor-pointer text-sm leading-5 text-blue-900 hover:underline"
      >
        {formatMessage({ id: 'logForPricing', defaultMessage: 'Log in for Exchange pricing' })}
      </span>
    );
  };

  const PriceDetailSection = (product) => {
    if (previewDateInSessionData?.isPreviewDate) {
      const futurePrice = futurePricing?.find((prod) => prod?.productId === product?.productId);
      return <PriceDetails product={futurePrice ?? product} />; // If no future price, passes the GB price
    }
    return <PriceDetails product={product} />;
  };

  const mapExternalPriceWithProduct = (fetchedPrices) => {
    const mappedProducts = products?.map((product) => {
      const updatedVariants = product?.variants
        ?.map((variant) => {
          const priceData = fetchedPrices?.find((priceObj) => Object.keys(priceObj)[0] === variant?.skuId);

          if (priceData) {
            const priceInfo = priceData[variant.skuId];
            return {
              skuId: variant.skuId,
              ...(priceInfo.salePrice && {
                salePrice: priceInfo.salePrice.centAmount / 100,
              }),
              ...(priceInfo.listPrice && {
                listPrice: priceInfo.listPrice.centAmount / 100,
              }),
              ...(priceInfo.msrpPrice && {
                msrp: priceInfo.msrpPrice.centAmount / 100,
              }),
            };
          }

          return null;
        })
        .filter(Boolean);

      return {
        productId: product?.productId,
        variants: updatedVariants,
      };
    });
    setfuturePricing(mappedProducts);
  };
  const getFururePriceBySkus = async (skus) => {
    const chunkSize = 300; // limit of the payload length
    const promises = [];
    setIsLoad(true);

    try {
      for (let i = 0; i < skus?.length; i += chunkSize) {
        const chunk = skus?.slice(i, i + chunkSize);
        promises.push(getSkusExternalPrices(chunk));
      }
      const fetchedPrices = await Promise.all(promises);
      const allFetchedPrices = fetchedPrices?.flat();
      mapExternalPriceWithProduct(allFetchedPrices);
    } catch (error) {
      console.error('Error fetching prices:', error);
    } finally {
      setIsLoad(false);
    }
  };

  const fetchPLPFuturePricing = async () => {
    if (products?.length > 0) {
      const skus = await products?.flatMap((product) => product?.variants?.map((variant) => variant?.skuId));
      getFururePriceBySkus(skus);
    }
  };
  const handlePDPNavigation = (pushURL) => {
    setIsLoad(true);
    router?.push(pushURL).then(() => {
      setIsLoad(false);
    });
  };

  const handleAddWishlist = (sku) => {
    if (loggedIn) {
      setIsLoad(true);
      addToWishlist(sku, 1)
        .then((response) => {
          if (response?.errorCode) {
            setCustomMsgModal(response?.body);
          }
        })
        .finally(() => {
          setIsLoad(false);
        });
    } else {
      setLoginModal(true);
    }
  };

  const handleRemoveWishlist = (sku) => {
    setIsLoad(true);
    removeLineItem(sku)
      .then((res) => {
        setIsLoad(false);
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleProductImageHover = async (sku: string) => {
    try {
      imagehoverStateRef.current.isHovered = true;
      imagehoverStateRef.current.activeSku = sku;
      const skuAltImage = getSkuAltImage(sku, altImages);
      if (skuAltImage?.isSkuFound) {
        if (skuAltImage?.url) {
          setHoverImage(skuAltImage?.url);
        }
      } else {
        // If there's an ongoing fetch,cancel it
        if (imagehoverStateRef.current.abortController) {
          imagehoverStateRef.current?.abortController?.abort();
        }
        // Create an AbortController instance for canceling the request
        imagehoverStateRef.current.abortController = new AbortController();
        const { signal } = imagehoverStateRef.current.abortController;
        const images = await getAltImages(sku, signal);
        if (Array.isArray(images) && images?.length > 0) {
          setAltImages((prevImages) => {
            const allImages = [...prevImages, ...images];
            const skuAltImage = getSkuAltImage(sku, images);
            if (skuAltImage?.url && imagehoverStateRef.current.isHovered) {
              setHoverImage(skuAltImage?.url);
            }
            return allImages;
          });
        }
      }
    } catch (error) {
      if (error?.name !== 'AbortError') {
        console.info('Error on hovering : ', error);
      }
    }
  };

  const handleProdutImageMouseLeave = () => {
    imagehoverStateRef.current.isHovered = false;
    imagehoverStateRef.current.activeSku = null;
    if (imagehoverStateRef.current.abortController) {
      imagehoverStateRef.current?.abortController?.abort();
      setHoverImage(null);
    }
  };

  function closeLoginModal() {
    setLoginModal(false);
  }

  const handleBorder = (index) => {
    if (index + 1 === selectedPageView) return null;
    if (index + 1 === productCount) {
      productCount = productCount + screenSize;
      return 'border-b-1';
    }
    if (index + 1 > selectedPageView - screenSize) return 'border-r-1';

    return 'border-b-1 border-r-1';
  };

  const fetchPLPDroplets = async () => {
    const tenderType = TENDER_TYPE;
    function extractAttributes(product) {
      const categories = [
        ...new Set(
          product?.parentCategories?.flatMap((category) => category?.split(' > ')?.map((item) => item?.trim())),
        ),
      ];
      const brands = product?.brands || '';
      const departmentCode = product?.departmentCode || '';
      const isDiscountable = product?.isDiscountable || true;

      return {
        categories,
        brands,
        departmentCode,
        isDiscountable,
        ...(product?.productSubType && { productSubType: product.productSubType }),
        ...(product?.style && { style: product.style }),
        ...(product?.gender && { gender: product.gender }),
        ...(product?.collections && { collections: product.collections }),
        ...(product?.productfeatures && { productfeatures: product.productfeatures }),
        ...(product?.occasions && { occasions: product.occasions }),
        ...(product?.league && { league: product.league }),
        ...(product?.SupplierId && { supplierId: product?.SupplierId }),
      };
    }
    function formatProductForApi(product) {
      const variant = product?.variants?.[0];
      return {
        productId: product?.repositoryId,
        skuId: variant?.skuId,
        activePrice: variant?.activePrice,
        attributes: {
          ...extractAttributes(product),
          ...(variant?.BranchofService && { BranchofService: variant?.BranchofService }),
          ...(account?.isMilStarCardHolder && { tenderType }),
        },
        ...(product?.category && { category: product.category }),
      };
    }
    const chunkSize = 32; // limit of the payload length
    const apiRequestPayload = products?.map(formatProductForApi);
    const promises = [];
    setIsLoad(true);
    for (let i = 0; i < apiRequestPayload?.length; i += chunkSize) {
      const chunk = apiRequestPayload.slice(i, i + chunkSize);
      promises.push(getDroplets(chunk));
    }

    try {
      const allPLPDroplets = await Promise.all(promises)
        .then((results) => results?.flat()?.filter((droplet) => !droplet?.errorCode))
        .catch((error) => {
          console.error('Error in PLP Droplets API calls:', error);
          return [];
        });
      setPlpDroplets(allPLPDroplets);
    } finally {
      setIsLoad(false);
    }
  };
  const getSkuAltImage = (sku: string, images: VariantAltImage[]) => {
    const skuImages = images?.find((altImage) => altImage?.sku === sku);
    return { isSkuFound: skuImages?.sku === sku, url: skuImages?.altImage };
  };

  useEffect(() => {
    const wishlistItems = wishlist?.lineItems;
    const filteredWishlistItems = [];
    if (loggedIn && wishlistItems) {
      wishlistItems?.forEach((item) => {
        filteredWishlistItems?.push({
          sku: item?.variant?.sku,
          lineItemId: item?.lineItemId,
        });
      });
      setWishlistItems(filteredWishlistItems);
    } else setWishlistItems(null);
  }, [wishlist, loggedIn]);

  useEffect(() => {
    const initBazaarvoice = () => {
      if (window.$BV) {
        clearInterval(intervalId);
        const productIds = products?.map((product) => product?.repositoryId);
        window.$BV.ui('rr', 'inline_ratings', {
          productIds: productIds,
          containerPrefix: 'BVRRInlineRating',
        });
      }
    };
    const intervalId = setInterval(() => {
      initBazaarvoice();
    }, 500);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [products]);

  useEffect(() => {
    if (previewDateInSessionData?.isPreviewDate && products && !previewDateSessionDataLoading) fetchPLPFuturePricing();
  }, [previewDateInSessionData, previewDateSessionDataLoading, products]);
  useEffect(() => {
    fetchPLPDroplets();
  }, [products, account?.isMilStarCardHolder]);

  return (
    <>
      <section className="my-3 grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-0 lg:grid-cols-3 xl:grid-cols-4">
        {products?.slice(0, products?.length).map((product, index) => {
          const productDroplets =
            plpDroplets?.find((droplet) => droplet?.skuId === product?.variants?.[0]?.skuId) || {};
          return (
            <ProductCell
              key={index}
              product={product}
              index={index}
              CompareSection={CompareSection}
              handleAddWishlist={handleAddWishlist}
              handleRemoveWishlist={handleRemoveWishlist}
              handleBorder={handleBorder}
              wishListItems={wishListItems}
              PriceDetailSection={PriceDetailSection}
              LoginForPriceSection={LoginForPriceSection}
              handlePDPNavigation={handlePDPNavigation}
              productDroplets={productDroplets}
              handleProductImageHover={handleProductImageHover}
              handleProdutImageMouseLeave={handleProdutImageMouseLeave}
              hoverImage={hoverImage}
              hoveringSku={imagehoverStateRef?.current?.activeSku}
            />
          );
        })}
      </section>
      <section>
        <div className="min-h-24 fixed bottom-0 left-0 z-10 mb-0 w-full bg-[#F1F1F1F2] shadow-[0px_-3px_4px_0px_#00000040]">
          <CompareProducts compareProducts={compareProducts} setCompareProducts={setCompareProducts} />
        </div>
      </section>
      {(isLoad || previewDateSessionDataLoading) && <Loader />}
      <LoginModal
        loginModal={loginModal}
        closeModal={closeLoginModal}
        closeAccountFlyout={closeLoginModal}
        loginCallBack={() => router.push(router.asPath)}
      />
      {customMsgModal !== null && (
        <CustomMessageModal
          closeModal={() => {
            setCustomMsgModal(null);
          }}
          customMessage={customMsgModal}
        />
      )}
    </>
  );
}
