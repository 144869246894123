export function formatDateCDTToLocal(cdtTimeString: string, useSlashFormat: boolean = false): string | null {
  try {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const date = new Date(cdtTimeString.replace(' ', 'T'));

    const monthIndex = date.getMonth();
    const monthName = months[monthIndex];
    const day = date.getDate();
    const year = date.getFullYear();

    if (useSlashFormat) {
      return `${monthIndex + 1}/${day}/${year}`;
    } else {
      return `${day} ${monthName} ${year}`;
    }
  } catch (error) {
    console.error('Error converting time:', error);
    return null;
  }
}
